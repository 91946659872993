<template>
    <div class="detail-tab-comment">
        <!-- 统计 -->
        <div class="detail-tab-comment-count">
            <!-- 评分 -->
            <div class="detail-tab-comment-count-score">
                <span>评分：</span>
                <span class="detail-tab-comment-count-score-num">{{data_rate}}</span>
                <el-rate
                    v-model="data_rate"
                    :colors="['var(--color)','var(--color)','var(--color)']"
                    disabled-void-color="#bebebe"
                    disabled>
                </el-rate>
            </div>
            <!-- 数据 -->
            <div class="detail-tab-comment-count-data">
                <!-- 月销量 -->
                <div>
                    <span>月销量：</span>
                    <span class="detail-tab-comment-count-data-num">7889</span>
                </div>
                <!-- 累计评价数 -->
                <div>
                    <span>累计评价数：</span>
                    <span class="detail-tab-comment-count-data-num">27889</span>
                </div>
                <!-- 好评率 -->
                <div>
                    <span>好评率：</span>
                    <span class="detail-tab-comment-count-data-num">97.3%</span>
                </div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="detail-tab-comment-content">
            <detail-tab-comment-item
                v-for="item in 5"
                :key="item.id">
            </detail-tab-comment-item>
        </div>
        <!-- 分页 -->
        <div class="detail-tab-comment-page">
            <el-pagination
                layout="prev, pager, next"
                :page-count="100"
                :pager-count="11">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data_rate: 4.6
            }
        },
        components: {
            detailTabCommentItem: () => import("@/components/imageMall/detailTabCommentItem")
        }
    }
</script>

<style scoped>
/* 统计 */
.detail-tab-comment-count{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: #fcfbff;
    border-radius: 6px;
}
/* 评分 */
.detail-tab-comment-count-score{
    display: flex;
    align-items: center;
}
.detail-tab-comment-count-score-num{
    margin: 0 10px;
    font-size: 22px;
    color: var(--color);
}
/* 数据 */
.detail-tab-comment-count-data{
    display: flex;
}
.detail-tab-comment-count-data > div + div{
    margin-left: 40px;
}
.detail-tab-comment-count-data-num{
    font-size: 16px;
    color: var(--color);
}

/* 内容 */
.detail-tab-comment-content{
    margin-top: 20px;
}
.detail-tab-comment-page{
    margin: 50px 0;
    text-align: center;
}
</style>